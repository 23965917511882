
import axios from 'axios';
import { FormData, FormResponse } from '../types/form';


const API_URL = import.meta.env.VITE_API_URL || "http://0.0.0.0:8055";

export const fetchForm = async (): Promise<FormData[]> => {
  try {
    const response = await axios.get(`${API_URL}/items/form`, {
      params: {
        fields: ["translations.*"],
      },
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching form data:", error);
    throw error;
  }
};

export const submitFormResponse = async (formData: FormResponse): Promise<void> => {
  try {
    await axios.post(`${API_URL}/items/form_responses`, formData);
  } catch (error) {
    console.error("Error submitting form:", error);
    throw error;
  }
};