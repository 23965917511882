import axios from "axios";
import { Footer, FooterResponse } from "../types/footer";

const API_URL = import.meta.env.VITE_API_URL || "http://0.0.0.0:8055";

export const fetchFooter = async (): Promise<Footer[]> => {
  try {
    const fieldsParams = ["translations.*"];

    const response = await axios.get<FooterResponse>(
      `${API_URL}/items/footer`,
      {
        params: {
          fields: fieldsParams,
        },
      }
    );

    return response.data.data;
  } catch (error) {
    console.error("Error fetching footer:", error);
    throw error;
  }
};
