import { useLocation, matchPath } from "react-router-dom";

const CONTENT_LIST_ROUTES = ["/", "/:categorySlug"];

export const useCurrentRoute = () => {
  const location = useLocation();

  const isAllContentList = CONTENT_LIST_ROUTES.some((path) =>
    matchPath({ path, end: true }, location.pathname)
  );

  return { isAllContentList };
};
