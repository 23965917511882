import { create } from 'zustand';

interface MenuState {
  currentStep: 'categories' | 'subcategories';
  tempSelectedCategory: string | null;
  tempSelectedSubcategories: string[];
  setCurrentStep: (step: 'categories' | 'subcategories') => void;
  setTempCategory: (categoryId: string | null) => void;
  setTempSubcategories: (subcategories: string[]) => void;
  resetTempSelections: () => void;
}

export const useMenuStore = create<MenuState>((set) => ({
  currentStep: 'categories',
  tempSelectedCategory: null,
  tempSelectedSubcategories: [],
  setCurrentStep: (step) => set({ currentStep: step }),
  setTempCategory: (categoryId) => set({ tempSelectedCategory: categoryId }),
  setTempSubcategories: (subcategories) => set({ tempSelectedSubcategories: subcategories }),
  resetTempSelections: () => set({ 
    tempSelectedCategory: null, 
    tempSelectedSubcategories: [] 
  }),
}));



interface MenuStateStore {
  isMenuOpen: boolean;
  toggleMenu: () => void;
  closeMenu: () => void;
  openMenu: () => void;
}

export const useMenuStateStore = create<MenuStateStore>((set) => ({
  isMenuOpen: false,
  toggleMenu: () => set((state) => ({ isMenuOpen: !state.isMenuOpen })),
  closeMenu: () => set({ isMenuOpen: false }),
  openMenu: () => set({ isMenuOpen: true }),
}));



// import { create } from 'zustand';

// interface MenuState {
//   currentStep: 'categories' | 'subcategories';
//   tempSelectedCategory: string | null;
//   tempSelectedSubcategories: string[];
//   setCurrentStep: (step: 'categories' | 'subcategories') => void;
//   setTempCategory: (categoryId: string | null) => void;
//   setTempSubcategories: (subcategories: string[]) => void;
//   resetTempSelections: () => void;
// }

// export const useMenuStore = create<MenuState>((set) => ({
//   currentStep: 'categories',
//   tempSelectedCategory: null,
//   tempSelectedSubcategories: [],
//   setCurrentStep: (step) => set({ currentStep: step }),
//   setTempCategory: (categoryId) => set({ tempSelectedCategory: categoryId }),
//   setTempSubcategories: (subcategories) => set({ tempSelectedSubcategories: subcategories }),
//   resetTempSelections: () => set({ 
//     currentStep: 'categories',
//     tempSelectedCategory: null, 
//     tempSelectedSubcategories: [] 
//   }),
// }));

// interface MenuStateStore {
//   isMenuOpen: boolean;
//   toggleMenu: () => void;
//   closeMenu: () => void;
//   openMenu: () => void;
// }

// export const useMenuStateStore = create<MenuStateStore>((set) => ({
//   isMenuOpen: false,
//   toggleMenu: () => set((state) => ({ isMenuOpen: !state.isMenuOpen })),
//   closeMenu: () => set({ isMenuOpen: false }),
//   openMenu: () => set({ isMenuOpen: true }),
// }));