
import styles from "./SavedContentDropdown.module.less";
import { Icon } from "../../shared/Icon/Icon";
import { useDropdownStore } from "../../../stores/dropdownStore";



const SavedContentDropdown = ({ theme , isMenu} : { theme: string, isMenu?: boolean } ) => {
  const { toggleDropdown, isOpen } = useDropdownStore();


  const handleClick = () => {
    toggleDropdown();

  };
 

  return (
    <div className={styles.savedContentDropdown}>
      <button onClick={handleClick} className={styles.savedContentButton}>
        <Icon
          id="save"
          className={`${styles.icon} ${theme !== "light" && styles.iconLight} ${isMenu  && styles.iconBig}  ${  isOpen && styles.iconActive}`}
        />
      </button>
    </div>
  );
};

export default SavedContentDropdown;
