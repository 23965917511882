import styles from "./ViewToggleButton.module.less";
import { Icon } from "../../shared/Icon/Icon";
import { useViewMode } from "../../../stores/viewModeStore";
import { useState } from "react";

const ViewToggleButton = ({ theme }: { theme: string }) => {
  const { toggleViewMode, viewMode } = useViewMode();
  const [isRotated, setIsRotated] = useState(false);

  const handleClick = () => {
    setIsRotated(!isRotated);
    toggleViewMode();
  };

  return (
    <button onClick={handleClick} className={styles.viewToggleButton}>
      <Icon
        id={viewMode === "list" ? "list" : "grid"}
        className={`${styles.icon} ${theme !== "light" ? styles.iconLight : ""}`}
        style={{ transform: `rotate(${isRotated ? 180 : 0}deg)` }}
      />
    </button>
  );
};

export default ViewToggleButton;
