import { useQuery } from "react-query";

import { ArtistInfo } from "../types/artistInfo";
import { fetchArtistInfo } from "../utils/artist_info";

export const useArtistInfo = (slug: string) => {
  return useQuery<ArtistInfo[], Error>(
    ["artistInfo", slug],
    () => fetchArtistInfo(slug),
    {
      enabled: !!slug,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    }
  );
};
