import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFilters } from "../../../hooks/useFilters";
import { useTags } from "../../../hooks/useTags";
import { useThemeStore } from "../../../stores/themeStore";
import { useAllContent } from "../../../hooks/useAllContent";
import { Icon } from "../../shared/Icon/Icon";

import styles from "./AdditionalFilter.module.less";

type ArchiveStatus = "current" | "archive" | null;

interface TempFiltersState {
  archiveStatus: ArchiveStatus;
  tags: Record<string, boolean>;
}

const AdditionalFilter: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [tempFilters, setTempFilters] = useState<TempFiltersState>({
    archiveStatus: null,
    tags: {},
  });

  const {
    filters,
    selectedCategory,
    selectedSubcategories,
    selectedYears,
    handleFilterChange,
    handleArchiveChange,
    clearAllFilters,
  } = useFilters();

  const { data: coursesData } = useAllContent({
    selectedCategory,
    selectedSubcategories,
    filters,
    selectedYears,
  });

  const { data: apiTags, isLoading, error } = useTags();

  console.log("apiTags", apiTags);
  const { theme } = useThemeStore();

  const availableFilters = useMemo(() => {
    if (!coursesData?.pages) {
      return { current: false, archive: false };
    }

    const filterAvailability = {
      current: false,
      archive: false,
    };

    coursesData.pages.forEach((page) => {
      page.data.forEach((course) => {
        if (course.archive === true) {
          filterAvailability.archive = true;
        } else {
          filterAvailability.current = true;
        }
      });
    });

    return filterAvailability;
  }, [coursesData]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const tagsToUse = useMemo(() => {
    if (apiTags && apiTags?.length > 0) {
      return apiTags.map((tag) => ({
        id: tag.id,
        name:
          tag.translations.find((t) => t.languages_code === i18n.language)
            ?.tag || tag.tag_name,
      }));
    }
    return [];
  }, [apiTags, i18n.language]);

  const selectedTags = useMemo(() => {
    return tagsToUse.filter((tag) => filters[tag.id]);
  }, [tagsToUse, filters]);

  const handleTempTagChange = (e: React.MouseEvent, tagId: string) => {
    e.preventDefault();
    e.stopPropagation();

    setTempFilters((prev) => ({
      ...prev,
      tags: {
        ...prev.tags,
        [tagId]: !prev.tags[tagId],
      },
    }));
  };

  const handleTempArchiveChange = (status: ArchiveStatus) => {
    setTempFilters((prev) => ({
      ...prev,
      archiveStatus: prev.archiveStatus === status ? null : status,
    }));
  };

  const handleToggleOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const tagsObject: Record<string, boolean> = {};
    Object.entries(filters).forEach(([key, value]) => {
      if (key !== "archive" && typeof value === "boolean") {
        tagsObject[key] = value;
      }
    });

    setTempFilters({
      archiveStatus: filters.archiveStatus as ArchiveStatus,
      tags: tagsObject,
    });

    setIsOpen(!isOpen);
  };

  const handleShow = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    Object.entries(tempFilters.tags).forEach(([tagId, isSelected]) => {
      if (tagId !== "archive") {
        handleFilterChange(tagId, isSelected);
      }
    });

    if (tempFilters.archiveStatus) {
      handleArchiveChange(tempFilters.archiveStatus);
    }

    setIsOpen(false);
  };

  const handleClear = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setTempFilters({
      archiveStatus: null,
      tags: {},
    });
    clearAllFilters();
  };

  const handleDropdownClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(false);
  };

  if (isLoading || error) return null;

  return (
    <div className={styles["additional-filters"]}>
      <button
        onClick={handleToggleOpen}
        className={styles["additional-filters__dropdown-toggle"]}
      >
        <Icon
          id="filter"
          className={`${styles.icon} ${theme !== "light" && styles.iconLight} ${
            (filters.archiveStatus || selectedTags.length > 0) &&
            styles.iconActive
          }`}
        />
      </button>

      {(filters.archiveStatus || selectedTags.length > 0) && (
        <div className={styles["additional-filters__selected"]}>
          <div
            className={styles["additional-filters__content"]}
            onClick={handleToggleOpen}
          >
            [{" "}
            {filters.archiveStatus && (
              <span className={styles["additional-filters__tag"]}>
                {filters.archiveStatus === "archive"
                  ? t("Архів")
                  : t("Актуальне")}
                {selectedTags.length > 0 ? ", " : ""}
              </span>
            )}
            {selectedTags.map((tag, index) => (
              <span key={tag.id} className={styles["additional-filters__tag"]}>
                {tag.name}
                {index !== selectedTags.length - 1 ? ", " : ""}
              </span>
            ))}{" "}
            ]
          </div>
        </div>
      )}

      {isOpen && (
        <div className={styles["additional-filters__dropdown"]}>
          <div
            className={styles["additional-filters__dropdown-content"]}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div className={styles["additional-filters__dropdown-header"]}>
              <h2 className={styles["additional-filters__title"]}>
                {t("Тип контенту")}
              </h2>
              <button
                className={styles["additional-filters__close-button"]}
                onClick={handleDropdownClose}
              >
                <Icon id="close-dropdown" className={styles["icon"]} />
              </button>
            </div>
            <div className={styles["additional-filters__buttons-container"]}>
              <div className={styles["additional-filters__filter-container"]}>
                <div className={styles["additional-filters__filter-buttons"]}>
                  {tagsToUse.map((tag) => (
                    <li className={styles["additional-filters__filter-item"]}>
                      <button
                        key={tag.id}
                        onClick={(e) => handleTempTagChange(e, tag.id)}
                        className={`
                  ${styles["additional-filters__filter-button"]}
                  ${tempFilters.tags[tag.id] ? styles["additional-filters__filter-button--active"] : ""}
                `}
                      >
                        {tag.name}
                        {tempFilters.tags[tag.id] && (
                          <Icon
                            id="close-dropdown"
                            className={styles["icon-cross"]}
                          />
                        )}
                      </button>
                    </li>
                  ))}
                </div>
              </div>

              <div className={styles["additional-filters__actions"]}>
                <div className={styles["additional-filters__filter-buttons"]}>
                  <li className={styles["additional-filters__filter-item"]}>
                    <button
                      onClick={() => handleTempArchiveChange("current")}
                      className={`
                ${styles["additional-filters__filter-button"]}
                ${tempFilters.archiveStatus === "current" ? styles["additional-filters__filter-button--active"] : ""}
                ${!availableFilters.current ? styles["additional-filters__filter-button--disabled"] : ""}
              `}
                    >
                      {t("current")}
                    </button>
                  </li>
                  <li className={styles["additional-filters__filter-item"]}>
                    <button
                      onClick={() => handleTempArchiveChange("archive")}
                      className={`
                ${styles["additional-filters__filter-button"]}
                ${tempFilters.archiveStatus === "archive" ? styles["additional-filters__filter-button--active"] : ""}
                ${!availableFilters.archive ? styles["additional-filters__filter-button--disabled"] : ""}
              `}
                    >
                      {t("archive")}
                    </button>
                  </li>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              className={styles["additional-filters__show-btn"]}
              onClick={handleShow}
            >
              {t("Show")}
            </button>
            <button
              onClick={handleClear}
              className={styles["additional-filters__clear-btn"]}
            >
              {t("Clear filters")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdditionalFilter;
