import React, { Suspense } from "react";
import { Route, Routes, Navigate, useParams } from "react-router-dom";
import Header from "./components/Header/Header";

import { SwitcherBlock } from "./components/SwitcherBlock/SwitcherBlock";

import SelectedFilters from "./components/shared/SelectedFilters/SelectedFilters";
import InfoArtistModal from "./components/InfoArtistModal/InfoArtistModal";
import { useArtistModalStore } from "./stores/artistModalStore";
import Form from "./components/Form/Form";
import { useFormModal } from "./stores/formStore";
import { AboutFundPage } from "./pages/AboutFundPage/AboutFundPage";

const AllContentList = React.lazy(
  () => import("./components/AllContent/AllContentList/AllContentList")
);
// const DictionaryPage = React.lazy(() => import("./pages/DictionaryPage"));
const DetailsPage = React.lazy(() => import("./pages/DetailsPage/DetailsPage"));

const DetailsPageWrapper: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  return <DetailsPage slug={slug} />;
};

const App: React.FC = () => {
  const { isOpen, currentSlug } = useArtistModalStore();

  const { isOpen: isFormOpen } = useFormModal();

  return (
    <div className="container">
      <Header />
      <SwitcherBlock />
      <SelectedFilters />

      {isFormOpen && <Form />}

      {isOpen && currentSlug && <InfoArtistModal slug={currentSlug} />}

      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path="/" element={<AllContentList />} />
          <Route path="/:categorySlug" element={<AllContentList />} />
          <Route path="/:categorySlug/:slug" element={<DetailsPageWrapper />} />
          <Route path="/about-fund" element={<AboutFundPage />} />
          {/* <Route path="/dictionary" element={<DictionaryPage />} /> */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>

      {/* <Footer /> */}
    </div>
  );
};

export default App;
