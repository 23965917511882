import { useQuery } from 'react-query';
import { fetchAboutFund } from '../utils/about_fund';
import { AboutFund } from '../types/about_fund';

export const useAboutFund = () => {
    return useQuery<AboutFund[], Error>('aboutFund', fetchAboutFund, {
      staleTime: 5 * 60 * 1000, 
      cacheTime: 30 * 60 * 1000, 
      refetchOnWindowFocus: false,
    });
  };