import axios from "axios";
import { AboutFund, AboutFundResponse } from "../types/about_fund";

const API_URL = import.meta.env.VITE_API_URL || "http://0.0.0.0:8055";

export const fetchAboutFund = async (): Promise<AboutFund[]> => {
  try {
    const fieldsParams = [
      "projects.allContent_id.translations.languages_code",
      "projects.allContent_id.members.artist_info_id.translations.artist_name",
      "projects.allContent_id.translations.title",
      "projects.allContent_id.slug",
      "projects.allContent_id.category.translations.*",
      "projects.allContent_id.years.years_id.year",
      "translations.*",
      "reports.*",
      "reports.translations.*",
      "projects.allContent_id.archive",
      "social_network",
    ];

    const response = await axios.get<AboutFundResponse>(
      `${API_URL}/items/about_fund`,
      {
        params: {
          fields: fieldsParams,
        },
      }
    );

    return response.data.data;
  } catch (error) {
    console.error("Error fetching about fund:", error);
    throw error;
  }
};
