import React from "react";
import { useFooter } from "../../hooks/useFooter";
import { useTranslatedContent } from "../../hooks/useTranslatedContent";
import styles from "./Footer.module.less";
import { useTranslation } from "react-i18next";
import { Icon } from "../shared/Icon/Icon";

export const Footer: React.FC = () => {
  const { data, isLoading } = useFooter();
  const { i18n } = useTranslatedContent();
  const { t } = useTranslation();

  if (isLoading || !data?.[0]) {
    return null;
  }

  const footerData = data[0];
  const currentTranslation =
    footerData.translations.find((t) => t.languages_code === i18n.language) ||
    footerData.translations.find((t) => t.languages_code === "uk");

  if (!currentTranslation) {
    return null;
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__bottom}>
        <div className={styles.footer__disclaimer}>
          {currentTranslation.disclaimer}
        </div>
        <div className={styles.footer__credits}>
          {t("Code and design")}
          {currentTranslation.credits.map((credit, index) => (
            <a
              key={index}
              href={credit.link}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.footer__creditLink}
            >
              {credit.title}

              <Icon id="arrow-btn-right" className={styles.icon} />
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};
