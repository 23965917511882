import { useDropdownStore } from "../../../stores/dropdownStore";

import { useTranslation } from "react-i18next";
import { Icon } from "../../shared/Icon/Icon";


import styles from "./DropdownHeader.module.less";
import useIsMobile from "../../../hooks/useIsMobile";

interface DropdownHeaderProps {
  value: string;
}

const DropdownHeader: React.FC<DropdownHeaderProps> = ({ value }) => {
  const { t } = useTranslation();
  const { toggleDropdown } = useDropdownStore();
  const isMobile = useIsMobile();
  return (
 

    <div className={styles.menuHeader__top}>
    <div>
      {!isMobile && (
        <span className={styles.menuHeader__title}>METHODFUND</span>
      )}
      <span className={styles.menuHeader__titleMenu}>{t(value)}</span>
    </div>
    <button className={styles.menuHeader__close} onClick={toggleDropdown}>
      <Icon id="cross" className={styles.icon} />
     
    </button>
  </div>
  );
};

export default DropdownHeader;
