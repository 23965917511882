import Dropdown from "../../Dropdown/Dropdown";
import { useSavedContent } from "../../../hooks/useSavedContent";
import { useDropdownStore } from "../../../stores/dropdownStore";
import styles from "./SaveContent.module.less";
import DropdownHeader from "../../Dropdown/DropdownHeader/DropdownHeader";

const SaveContent = () => {
  const { savedContent, removeSavedContent } = useSavedContent();
  const { isOpen, toggleDropdown } = useDropdownStore();

  return (
    <>
      {isOpen && (
        <div className={styles["saved-content"]}>
          <div className={styles["saved-content__container"]}>
            <div className={styles["saved-content__header"]}>
              <DropdownHeader value="savedContent" />
            </div>
            <div className={styles["saved-content__wrapper"]}>
              {savedContent.length > 0 ? (
                <div className={styles["saved-content__body"]}>
                  <Dropdown
                    isOpen={isOpen}
                    onClose={toggleDropdown}
                    items={savedContent}
                    groupByCategory={false}
                    onItemRemove={removeSavedContent}
                    showHeader={false}
                  />
                </div>
              ) : (
                <div className={styles["saved-content__empty"]}>
                  No saved content
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SaveContent;
