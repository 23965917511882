import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useCategories } from "../../../hooks/useCategories";
import { useFilters } from "../../../hooks/useFilters";
import { useTranslatedContent } from "../../../hooks/useTranslatedContent";
import {
  CategoriesData,
  Category,
  Subcategory,
} from "../../../types/categories";
import AdditionalFilter from "../AdditionalFilter/AdditionalFilter";

import { Icon } from "../../shared/Icon/Icon";
import { useThemeStore } from "../../../stores/themeStore";

import styles from "./Categories.module.less";
import { useSearchStore } from "../../../stores/searchStore";
import { useDropdownStore } from "../../../stores/dropdownStore";
import { useMenuStateStore } from "../../../stores/menuStore";

const Categories: React.FC = () => {
  const { t } = useTranslation();
  const { data: categoriesData, isError, error } = useCategories();
  const {
    selectedCategory,
    selectedSubcategories,

    setSelectedCategory,
    setSelectedSubcategories,
  } = useFilters();
  const { getTranslation } = useTranslatedContent();
  const { theme } = useThemeStore();
    const { isSearchVisible} = useSearchStore();
    const { isOpen } = useDropdownStore();
      const { isMenuOpen } = useMenuStateStore();

  const navigate = useNavigate();

  const typedCategoriesData = categoriesData as CategoriesData | undefined;

  const handleCategoryClick = (categoryId: string, categorySlug: string) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' 
    });
 
    const newCategory = selectedCategory === categoryId ? null : categoryId;
    setSelectedCategory(newCategory);
    navigate(newCategory ? `/${categorySlug}` : "/");
    setSelectedSubcategories([]);
    
  };

  const squareBlockClick = () => {
    setSelectedCategory(null);
    setSelectedSubcategories([]);
  };

  const handleSubcategoryClick = (subcategoryId: string) => {
    setSelectedSubcategories((prev) =>
      prev.includes(subcategoryId)
        ? prev.filter((id) => id !== subcategoryId)
        : [...prev, subcategoryId]
    );
  };

  const selectedCategoryData = useMemo(() => {
    if (!selectedCategory || !typedCategoriesData) return null;
    return typedCategoriesData.categories.find(
      (category) => category.id === selectedCategory
    );
  }, [selectedCategory, typedCategoriesData]);

  const filteredSubcategories = useMemo(() => {
    if (!selectedCategory || !typedCategoriesData) return [];
    return typedCategoriesData.subcategories.filter((subcategory) =>
      subcategory.categories.some(
        (cat) => cat.categories_id === selectedCategory
      )
    );
  }, [selectedCategory, typedCategoriesData]);

  if (isError) {
    return <div>Error: {(error as Error).message}</div>;
  }

  if (!typedCategoriesData) {
    return null;
  }

  return (
    <div className={styles.categoriesContainer}>
      {selectedCategory && !isSearchVisible && !isOpen  && !isMenuOpen&& (
        <div
          className={`${styles.squareBlock} ${styles.clickable}`}
          onClick={squareBlockClick}
        >
          <Icon
            id="arrow"
            className={`${styles.icon} ${theme !== "light" && styles.iconLight}`}
          />
        </div>
      )}
      <div className={styles.categories}>
        {selectedCategoryData ? (
          <>
            <li className={styles.categoryButtonContainer}>
              <button
                className={`${styles.categoryButton} ${styles.categoryButtonActive}`}
                onClick={() =>
                  handleCategoryClick(
                    selectedCategoryData.id,
                    selectedCategoryData.slug
                  )
                }
              >
                {getTranslation(selectedCategoryData, "category_name")}
            
               
              </button>
            
            </li>

            <span className={styles.categoriesDecor}>/</span>
            <div
              className={`${styles.subcategoryList} ${styles.subcategoryListOpen}`}
            >
              <ul className={styles.subcategoryList}>
                {filteredSubcategories.map((subcategory: Subcategory) => (
                  <li className={styles.categoryButtonContainer}>
                    <button
                      key={subcategory.id}
                      className={`${styles.categoryButton} ${
                        styles.subcategoryButton
                      } ${
                        selectedSubcategories.includes(subcategory.id)
                          ? styles.subcategoryButtonActive
                          : ""
                      }`}
                      onClick={() => handleSubcategoryClick(subcategory.id)}
                    >
                      {getTranslation(subcategory, "subcategory_name")}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : (
          <ul className={styles.mainCategories}>
            {typedCategoriesData.categories.map((category: Category) => (
              <li className={styles.categoryButtonContainer}>
                <button
                  key={category.id}
                  className={styles.categoryButton}
                  onClick={() =>
                    handleCategoryClick(category.id, category.slug)
                  }
                >
                  {getTranslation(category, "category_name")}
                </button>
              </li>
            ))}
            <Link to="/about-fund">
            <li className={styles.categoryButtonContainer}>
              <button className={styles.categoryButton}>
                {t("About Fund")}
              </button>
              </li >
            </Link>
            <AdditionalFilter />
          </ul>
        )}
      </div>
    </div>
  );
};

export default React.memo(Categories);
