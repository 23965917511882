import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  uk: {
    translation: {
      mainMenu: "Меню",
      "All projects": "Всі проекти",
      Show: "Показати",
      Back: "Назад",
      "Clear filters": "Очистити фільтри",
      savedCourses: "Збережені курси",
      remove: "Видалити",
      noSavedCourses: "Немає збережених курсів",
      searchCourses: "Пошук курсів...",
      dictionaryButton: "Словник",
      additionalFilters: "Додаткові фільтри",
      hasVideo: " відео",
      hasAudio: " аудіо",
      hasPhoto: " фото",
      notActual: "Неактуальний",
      clear: "Очистити ",
      loading: "Завантаження...",
      noMoreCourses: "Більше курсів немає",
      noCourses: "Курси не знайдено",
      year: "Рік",
      saveCourse: "Зберегти курс",
      courseProgram: "Програма курсу",
      courseDetails: "Деталі курсу",
      saved: "Збережено",
      noSavedContent: "Немає збереженого вмісту",
      video: "Відео",
      save: "Зберегти",
      contains: "Містить",
      photo: "Фото",
      uncategorized: "Без категорії",
      searchContent: "Пошук вмісту",
      actual: "Актуальний",
      archive: "Архів",
      "join us": "Приєднайтесь до навчання",
      current: "Поточний",
      removeFromSaved: "Видалити зі збережених",
      savedContent: "Збереженe",
      Year: "Рік",
      "Use Year": "Використовувати рік",
      Reset: "Скинути",
      "Back to top": "На початок списку",
      "Search...": "Пошук...",
      "Sign up for a course": "Зареєструватися на курс",
      Submit: "Відправити",
      "Select theme": "Оберіть тему",
      "Back to site": "Повернутися до сайту",
      Name: "Назва",
      Project: "Проект",
      Who: "Хто",
      Actual: "Актуальні",
      Reports: "Звіти",
      "About Fund": "Про Фонд",
    },
  },
  en: {
    translation: {
      mainMenu: "Menu",
      "All projects": "All projects",
      Back: "Back",
      "Clear filters": "Clear filters",
      savedCourses: "Saved Courses",
      remove: "Remove",
      noSavedCourses: "No saved courses",
      searchCourses: "Search courses...",
      dictionaryButton: "Dictionary",
      additionalFilters: "Additional Filters",
      hasVideo: "Has Video",
      hasAudio: "Has Audio",
      hasPhoto: "Has Photo",
      notActual: "Not Actual",
      clearAllFilters: "Clear All Filters",
      loading: "Loading...",
      noMoreCourses: "No more courses",
      noCourses: "No courses found",
      year: "Year",
      saveCourse: "Save Course",
      courseProgram: "Course Program",
      courseDetails: "Course Details",
      saved: "Saved",
      noSavedContent: "No saved content",
      video: "Video",
      actual: "Current",
      archive: "Archived",
      save: "Save",
      contains: "Contains",
      photo: "Photo",
      uncategorized: "Uncategorized",
      "join us": "Join us",
      searchContent: "Search Content",
      current: "Current",
      removeFromSaved: "Remove from saved",
      "Зареєструватися на курс": "Sign up for a course",
      savedContent: "Saved Content",
      Year: "Year",
      "Use Year": "Use Year",
      Reset: "Reset",
      "Back to top": "Back to top",
      "Search...": "Search...",
      "Sign up for a course": "Sign up for a course",
      Submit: "Submit",
      "Back to site": "Back to site",
      "Code and design": "Код та дизайн",
      View: "Дивитися",
    },
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    debug: true,
    supportedLngs: ["en", "uk"],
    ns: ["translation"],
    defaultNS: "translation",
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    detection: {
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      caches: ["localStorage", "cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
