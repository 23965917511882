import styles from "./LinkHandler.module.less";

interface LinkHandlerOptions {
  openArtistModal: (slug: string) => void;
  openFormModal: () => void;
}

export const setupLinkHandlers = (
  containerElement: HTMLElement,
  options: LinkHandlerOptions
): (() => void) => {
  const cleanup: (() => void)[] = [];

  const links = containerElement.querySelectorAll("a");

  links.forEach((link) => {
    if (link.parentElement?.classList.contains(styles.linkWrapper)) {
      link.parentElement.replaceWith(link);
    }

    const href = link.getAttribute("href");

    if (href?.startsWith("/artist/")) {
      link.classList.add(styles.artistLink);
      const handleClick = (e: Event) => {
        e.preventDefault();
        const slug = href.split("/artist/")[1];
        options.openArtistModal(slug);
      };
      link.addEventListener("click", handleClick);
      cleanup.push(() => link.removeEventListener("click", handleClick));
    } else if (href === "/form") {
      link.classList.add(styles.formLink);
      const handleClick = (e: Event) => {
        e.preventDefault();
        options.openFormModal();
      };
      link.addEventListener("click", handleClick);
      cleanup.push(() => link.removeEventListener("click", handleClick));
    } else if (link.hostname !== window.location.hostname) {
      link.classList.add(styles.externalLink);
      link.setAttribute("rel", "noopener noreferrer");
      link.setAttribute("target", "_blank");
    }
  });

  return () => {
    cleanup.forEach((fn) => fn());
  };
};
