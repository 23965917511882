
import { create } from "zustand";

interface DropdownState {
  isDropdownOpen: boolean;
  openDropdown: () => void;
  closeDropdown: () => void;
  toggleDropdown: () => void;
}

export const useDropdownStore = create<DropdownState>()((set) => ({
  isDropdownOpen: false,
  openDropdown: () => {
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
    document.body.style.width = "100%";
    document.body.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`;
    set({ isDropdownOpen: true });
  },
  closeDropdown: () => {
    document.body.style.overflow = "unset";
    document.body.style.position = "static";
    document.body.style.width = "auto";
    document.body.style.paddingRight = "0";
    set({ isDropdownOpen: false });
  },
  toggleDropdown: () => 
    set((state) => {
      if (!state.isDropdownOpen) {
        document.body.style.overflow = "hidden";
        document.body.style.position = "fixed";
        document.body.style.width = "100%";
        document.body.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`;
      } else {
        document.body.style.overflow = "unset";
        document.body.style.position = "static";
        document.body.style.width = "auto";
        document.body.style.paddingRight = "0";
      }
      return { isDropdownOpen: !state.isDropdownOpen };
    }),
}));