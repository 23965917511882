import { useEffect, useState } from "react";
import { useArtistInfo } from "../../hooks/useArtistInfo";
import { useArtistModalStore } from "../../stores/artistModalStore";
import { useTranslatedContent } from "../../hooks/useTranslatedContent";
import { Link } from "react-router-dom";
import style from "./InfoArtistModal.module.less";
import { useTranslation } from "react-i18next";
import { Icon } from "../shared/Icon/Icon";
import {
  ArtistInfo,
  Project,
  ProjectTranslation,
} from "../../types/artistInfo";

const InfoArtistModal = ({ slug }: { slug: string }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { data: artistInfo, isLoading, error } = useArtistInfo(slug);
  const { getTranslation, i18n } = useTranslatedContent();
  const { closeModal } = useArtistModalStore();

  const { t } = useTranslation();

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen && window.innerWidth <= 768) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  const getProjectTitle = (translations: ProjectTranslation[]) => {
    const currentLang = i18n.language;
    return (
      translations.find((t) => t.languages_code === currentLang)?.title ||
      translations[0]?.title ||
      "Untitled Project"
    );
  };

  const sortProjects = (projects: Project[]) => {
    const actual = projects.filter((p) => !p.allContent_id.archive);
    const archived = projects.filter((p) => p.allContent_id.archive);

    const sortById = (a: Project, b: Project) => a.sort - b.sort;

    return {
      actual: actual.sort(sortById),
      archived: archived.sort(sortById),
    };
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!artistInfo?.[0]) return <div>No artist information available</div>;

  const currentArtist = artistInfo[0] as ArtistInfo;
  const hasProjects = currentArtist.projects?.length > 0;
  const sortedProjects = hasProjects
    ? sortProjects(currentArtist.projects)
    : null;

  return (
    <div className={`${style.artistInfo} ${isOpen ? style.open : ""}`}>
      <div className={style.artistInfo__header}>
        <p className={style.artistInfo__name}>
          {getTranslation(currentArtist, "artist_name")}
        </p>
        {isOpen ? (
          <div className={style.artistInfo__buttons}>
            <button
              onClick={toggleModal}
              className={style.artistInfo__buttonHide}
            ></button>
            <button onClick={closeModal} className={style.artistInfo__button}>
              <Icon id="close-dropdown" className={`${style.icon}`} />
            </button>
          </div>
        ) : (
          <button
            className={style.artistInfo__buttonSquare}
            onClick={toggleModal}
          ></button>
        )}
      </div>

      {isOpen && (
        <div className={style.artistInfo__body}>
          <div
            className={style.artistInfo__description}
            dangerouslySetInnerHTML={{
              __html: getTranslation(currentArtist, "description"),
            }}
          />

          {hasProjects && (
            <div className={style.projectsContainer}>
              {sortedProjects?.actual && sortedProjects?.actual.length > 0 && (
                <div className={style.artistInfo__projects}>
                  <h3 className={style.artistInfo__title}>{t("actual")}</h3>
                  <ul>
                    {sortedProjects.actual.map((project) => (
                      <Link
                        to={`/${project.allContent_id.category?.slug || "projects"}/${project.allContent_id.slug}`}
                        key={project.id}
                        className={style.artistInfo__project}
                      >
                        {project.allContent_id?.category && (
                          <p className={style.artistInfo__category}>
                            {getTranslation(
                              project.allContent_id.category,
                              "category_name"
                            )}
                            <span className={style.artistInfo__decor}>/</span>
                          </p>
                        )}

                        {getProjectTitle(project.allContent_id.translations)}
                      </Link>
                    ))}
                  </ul>
                </div>
              )}

              {sortedProjects?.archived &&
                sortedProjects?.archived.length > 0 && (
                  <>
                    <h3 className={style.artistInfo__title}>{t("archive")}</h3>
                    <ul>
                      {sortedProjects.archived.map((project) => (
                        <Link
                          to={`/${project.allContent_id.category?.slug || "projects"}/${project.allContent_id.slug}`}
                          key={project.id}
                          className={`${style.artistInfo__project} ${style.artistInfo__projectArchived}`}
                        >
                          {project.allContent_id?.category && (
                            <p className={style.artistInfo__category}>
                              {getTranslation(
                                project.allContent_id.category,
                                "category_name"
                              )}
                              <span className={style.artistInfo__decor}>/</span>
                            </p>
                          )}
                          {getProjectTitle(project.allContent_id.translations)}
                        </Link>
                      ))}
                    </ul>
                  </>
                )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default InfoArtistModal;
