import axios from "axios";
import { ArtistInfo } from "../types/artistInfo";

const API_URL = import.meta.env.VITE_API_URL || "http://0.0.0.0:8055";

export const fetchArtistInfo = async (slug: string): Promise<ArtistInfo[]> => {
  try {
    const response = await axios.get(`${API_URL}/items/artist_info`, {
      params: {
        filter: {
          slug: {
            _eq: slug,
          },
        },
        fields: [
          "*.*",
          "projects.allContent_id.slug",
          "projects.allContent_id.translations.*",
          "translations",
          "projects.allContent_id.archive",
          "projects.allContent_id.category.slug",
          "projects.allContent_id.category.translations.*",
        ],
      },
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching tags:", error);
    throw error;
  }
};
