import { useQuery } from 'react-query';
import { Footer } from '../types/footer';
import { fetchFooter } from '../utils/footer';

export const useFooter = () => {
  return useQuery<Footer[], Error>('footer', fetchFooter, {
    staleTime: 5 * 60 * 1000, 
    cacheTime: 30 * 60 * 1000,
    refetchOnWindowFocus: false,
  });
};