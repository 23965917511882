import { create } from "zustand";

type ViewMode = "list" | "grid";

interface ViewModeState {
  viewMode: ViewMode;
  toggleViewMode: () => void;
}

const getInitialViewMode = (): ViewMode => {
  try {
    const savedViewMode = window.localStorage.getItem("contentViewMode");
    return savedViewMode === "list" || savedViewMode === "grid"
      ? (savedViewMode as ViewMode)
      : "list";
  } catch {
    return "list";
  }
};

export const useViewModeStore = create<ViewModeState>()((set) => ({
  viewMode: getInitialViewMode(),
  toggleViewMode: () =>
    set((state) => {
      const newViewMode = state.viewMode === "list" ? "grid" : "list";
      try {
        window.localStorage.setItem("contentViewMode", newViewMode);
      } catch (error) {
        console.error("Failed to save viewMode:", error);
      }
      return { viewMode: newViewMode };
    }),
}));

export const useViewMode = () => {
  return useViewModeStore();
};
