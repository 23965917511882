import React, { useState, useCallback } from "react";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { searchAllContent } from "../../../utils/api";
import { AllContent } from "../../../types";
import { Icon } from "../../shared/Icon/Icon";
import { useSearchStore } from "../../../stores/searchStore";

import Dropdown from "../../Dropdown/Dropdown";

import styles from "./SearchInput.module.less";
import Menu from "../Menu/Menu";

interface SearchInputProps {
  toggleSearch: () => void;
}

const SearchInput: React.FC<SearchInputProps> = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<AllContent[]>([]);

  const [error, setError] = useState<string | null>(null);

  const { isSearchVisible, toggleSearch } = useSearchStore();

  const { t } = useTranslation();

  const debouncedSearch = useCallback(
    debounce(async (value: string) => {
      if (value.length >= 2) {
        setError(null);
        try {
          const results = await searchAllContent(value);
          setSearchResults(results);
        } catch (error) {
          setError(t("searchError"));
          setSearchResults([]);
        }
      } else {
        setSearchResults([]);
        setError(null);
      }
    }, 300),
    [t]
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchQuery(value);
    debouncedSearch(value);
  };

  return (
    <div className={styles.searchInputContainer}>
      <div className={styles.searchWrapper}>
        <input
          type="text"
          placeholder={t("Search...")}
          value={searchQuery}
          onChange={handleSearchChange}
          className={styles.searchInput}
        />
        <Dropdown
          showHeader={false}
          isOpen={isSearchVisible}
          onClose={toggleSearch}
          items={searchResults}
          groupByCategory={true}
        />
        <button className={styles.closeButton} onClick={toggleSearch}>
          <Icon id="close-dropdown" className={`${styles.icon} `} />
        </button>
        <div className={styles.iconsWrapper}>
        <Menu />
        </div>
      </div>
      {error && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
};

export default SearchInput;
