import { create } from "zustand";
import { useLocalStorage } from "usehooks-ts";

type Theme = "light" | "dark";

interface ThemeState {
  theme: Theme;
  toggleTheme: () => void;
}

const getInitialTheme = (): Theme => {
  const savedTheme = localStorage.getItem("theme") as Theme;
  return savedTheme || "light";
};

export const useThemeStore = create<ThemeState>()((set) => ({
  theme: getInitialTheme(),
  toggleTheme: () =>
    set((state) => {
      const newTheme = state.theme === "light" ? "dark" : "light";
      localStorage.setItem("theme", newTheme);
      document.body.setAttribute("data-theme", newTheme);
      return { theme: newTheme };
    }),
}));

document.body.setAttribute("data-theme", getInitialTheme());

export const useTheme = () => {
  const { theme, toggleTheme } = useThemeStore();

  useLocalStorage("theme", theme);

  return { theme, toggleTheme };
};
