






import { create } from 'zustand';
import { useLocalStorage } from "usehooks-ts";
import React from 'react';

interface ArtistModalState {
  isOpen: boolean;
  currentSlug: string | null;
  setIsOpen: (isOpen: boolean) => void;
  openModal: (slug: string) => void;
  closeModal: () => void;
}

// Отримуємо початкові значення з localStorage
const getInitialState = () => {
  try {
    const savedSlug = window.localStorage.getItem("artistModalSlug");
    const savedIsOpen = window.localStorage.getItem("artistModalIsOpen");
    return {
      currentSlug: savedSlug ? savedSlug : null,
      isOpen: savedIsOpen === 'true'
    };
  } catch {
    return {
      currentSlug: null,
      isOpen: false
    };
  }
};

// Основний store
export const useArtistModalStore = create<ArtistModalState>()((set) => ({
  ...getInitialState(),
  
  setIsOpen: (isOpen) => set(() => {
    window.localStorage.setItem("artistModalIsOpen", String(isOpen));
    return { isOpen };
  }),
  
  openModal: (slug) => set(() => {
    window.localStorage.setItem("artistModalSlug", slug);
    window.localStorage.setItem("artistModalIsOpen", "true");
    return { 
      isOpen: true,
      currentSlug: slug 
    };
  }),
  
  closeModal: () => set(() => {
    window.localStorage.setItem("artistModalSlug", "");
    window.localStorage.setItem("artistModalIsOpen", "false");
    return { 
      isOpen: false,
      currentSlug: null 
    };
  })
}));

// Хук з синхронізацією localStorage
export const useArtistModal = () => {
  const store = useArtistModalStore();
  
  const [storedSlug] = useLocalStorage<string | null>(
    "artistModalSlug",
    store.currentSlug
  );
  
  const [storedIsOpen] = useLocalStorage<boolean>(
    "artistModalIsOpen",
    store.isOpen
  );

  // Синхронізуємо значення при монтуванні
  React.useEffect(() => {
    if (storedSlug !== store.currentSlug || storedIsOpen !== store.isOpen) {
      if (storedIsOpen && storedSlug) {
        store.openModal(storedSlug);
      } else {
        store.closeModal();
      }
    }
  }, []);

  return store;
};