import Icons from "../../../sprite.svg";

export const Icon = ({
  id,
  className,
  style,
}: {
  id: string;
  className: string;
  style?: React.CSSProperties;
}) => {
  return (
    <svg className={className} style={style}>
      <use href={Icons + `#${id}`}></use>
    </svg>
  );
};
