import { create } from "zustand";

interface SearchStore {
  isSearchVisible: boolean;
  toggleSearch: () => void;
  closeSearch: () => void;
}

export const useSearchStore = create<SearchStore>((set) => ({
  isSearchVisible: false,
  toggleSearch: () =>
    set((state) => ({ isSearchVisible: !state.isSearchVisible })),

  closeSearch: () => set({ isSearchVisible: false }),
}));
