import React, { useMemo, useCallback, useState, useEffect } from "react";
import { useQuery } from "react-query";
import styles from "./YearsList.module.less";
import { useFilters } from "../../hooks/useFilters";
import { fetchFilteredAllContent } from "../../utils/api";
import { Icon } from "../shared/Icon/Icon";
import useIsMobile from "../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";

const YearsList: React.FC = () => {
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const [tempSelectedYear, setTempSelectedYear] = useState<number | null>(null);

  const {
    selectedYears,
    setSelectedYears,
    filters,
    selectedCategory,
    selectedSubcategories,
  } = useFilters();

  const { t } = useTranslation();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (showMobileFilter && isMobile) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`;
    } else {
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = "0";
    }

    return () => {
      document.body.style.overflow = "unset";
      document.body.style.paddingRight = "0";
    };
  }, [showMobileFilter]);

  const { data: availableContentYears } = useQuery(
    ["availableYears", selectedCategory, selectedSubcategories, filters],
    async () => {
      const response = await fetchFilteredAllContent({
        pageParam: 1,
        categoryId: selectedCategory,
        subcategoryIds: selectedSubcategories,
        filters,
        selectedYears: [],
      });

      const years = new Set<number>();
      response.data.forEach((content) => {
        if (content.years && Array.isArray(content.years)) {
          content.years.forEach((yearObj) => {
            if (yearObj.years_id && typeof yearObj.years_id.year === "number") {
              years.add(yearObj.years_id.year);
            }
          });
        }
      });

      return years;
    },
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    }
  );

  const availableYears = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const years = Array.from(
      { length: currentYear - 2016 + 1 },
      (_, i) => 2016 + i
    );
    return years.reverse();
  }, []);

  useEffect(() => {
    if (showMobileFilter) {
      setTempSelectedYear(selectedYears[0] || null);
    }
  }, [showMobileFilter]);

  const handleMobileYearSelect = useCallback((year: number) => {
    setTempSelectedYear((prev) => (prev === year ? null : year));
  }, []);

  const handleApplyYear = useCallback(() => {
    if (tempSelectedYear !== null) {
      setSelectedYears([tempSelectedYear]);
      setShowMobileFilter(false);
    }
  }, [tempSelectedYear, setSelectedYears]);

  const handleDesktopYearSelect = useCallback(
    (year: number) => {
      setSelectedYears((prevYears) => {
        if (prevYears.includes(year)) {
          return [];
        }
        return [year];
      });
    },
    [setSelectedYears]
  );

  const handleReset = useCallback(() => {
    setSelectedYears([]);
    setTempSelectedYear(null);
    setShowMobileFilter(false);
  }, [setSelectedYears]);

  const selectedYear = selectedYears[0] || "Select Year";

  const isYearActive = (year: number) => {
    if (showMobileFilter) {
      return (
        tempSelectedYear === year ||
        (tempSelectedYear === null && selectedYears.includes(year))
      );
    }
    return selectedYears.includes(year);
  };

  const handleYearClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowMobileFilter((prev) => !prev);
  };

  return (
    <div className={styles["yearsList"]}>
      <div className={styles["yearsList__mobile"]}>
        <button
          onClick={handleYearClick}
          className={styles["yearsList__filter-button"]}
        >
          <span>
            {selectedYear !== "Select Year"
              ? `[${selectedYear}]`
              : `[${availableYears[0]}]`}
          </span>
        </button>

        {showMobileFilter && (
          <div className={styles["yearsList__mobile-overlay"]}>
            <div className={styles["yearsList__mobile-header"]}>
              <span>{t("Year")}</span>
              <button
                onClick={() => setShowMobileFilter(false)}
                className={styles["yearsList__close-button"]}
              >
                <Icon id="close-dropdown" className={`${styles.icon}`} />
              </button>
            </div>
            <div className={styles["yearsList__mobile-content"]}>
              {availableYears.map((year) => (
                <li
                  key={year}
                  onClick={() => handleMobileYearSelect(year)}
                  className={`${styles["yearsList__dropdown-item"]} ${
                    !availableContentYears?.has(year)
                      ? styles["yearsList__dropdown-item--disabled"]
                      : ""
                  }`}
                >
                  <button
                    className={`${styles["yearsList__dropdown-button"]} ${
                      isYearActive(year)
                        ? styles["yearsList__button--active"]
                        : ""
                    }`}
                    disabled={!availableContentYears?.has(year)}
                  >
                    {year}
                  </button>
                </li>
              ))}
            </div>

            <div className={styles["yearsList__mobile-footer"]}>
              <button
                onClick={handleApplyYear}
                className={styles["yearsList__apply-button"]}
                disabled={tempSelectedYear === null}
              >
                {t("Show")}
              </button>

              <button
                onClick={handleReset}
                className={styles["yearsList__reset-button"]}
                disabled={
                  selectedYears.length === 0 && tempSelectedYear === null
                }
              >
                {t("Back to top")}
              </button>
            </div>
          </div>
        )}
      </div>

      <div className={styles["yearsList__buttons"]}>
        {availableYears.map((year) => (
          <button
            key={year}
            onClick={() => handleDesktopYearSelect(year)}
            className={`${styles["yearsList__button"]} ${
              selectedYears.includes(year)
                ? styles["yearsList__button--active"]
                : ""
            } ${
              !availableContentYears?.has(year)
                ? styles["yearsList__button--disabled"]
                : ""
            }`}
            disabled={!availableContentYears?.has(year)}
          >
            {year}
          </button>
        ))}
      </div>
    </div>
  );
};

export default YearsList;
