import { useState, useEffect, useRef } from "react";
import styles from "./CustomSelect.module.less";
import { Icon } from "../../shared/Icon/Icon";

interface CustomSelectProps {
  options: { theme: string }[];
  value: string;
  onChange: (e: any) => void;
  placeholder: string;
  required?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  value,
  onChange,
  placeholder,
  required,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className={styles.selectWrapper} ref={dropdownRef}>
      <div className={styles.customSelect} onClick={() => setIsOpen(!isOpen)}>
        <span className={value ? styles.selectedValue : styles.placeholder}>
          {value || placeholder}
        </span>
        <Icon
          id="vector"
          className={`${styles.dropdownIcon} ${isOpen ? styles.rotated : ""}`}
        />
      </div>

      <div className={`${styles.optionsList} ${isOpen ? styles.open : ""}`}>
        {options.map((option, index) => (
          <div
            key={index}
            className={`${styles.option} ${value === option.theme ? styles.selected : ""}`}
            onClick={() => {
              onChange({ target: { name: "theme", value: option.theme } });
              setIsOpen(false);
            }}
          >
            {option.theme}
          </div>
        ))}
      </div>
      {required && value === "" && (
        <input
          type="text"
          required
          style={{ position: "absolute", opacity: 0, height: 0 }}
        />
      )}
    </div>
  );
};

export default CustomSelect;
