import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "../../../shared/Icon/Icon";
import { useTranslatedContent } from "../../../../hooks/useTranslatedContent";
import styles from "./HeaderMenu.module.less";
import useIsMobile from "../../../../hooks/useIsMobile";
import { Category } from "../../../../types/categories";

interface MenuHeaderProps {
  onClose: () => void;
  onApplyFilters: () => void;
  selectedCategory?: Category;
  onBack?: () => void;
  showBackButton?: boolean;
  showApplyButton?: boolean;
}

const MenuHeader: React.FC<MenuHeaderProps> = ({
  onClose,
  onApplyFilters,
  selectedCategory,
  onBack,
  showBackButton = false,
  showApplyButton = false,
}) => {
  const { t} = useTranslation();

  const { getTranslation } = useTranslatedContent();
  const isMobile = useIsMobile();



  return (
    <div className={styles.menuHeader}>
      <div className={styles.menuHeader__top}>
        <div>
          {!isMobile && (
            <span className={styles.menuHeader__title}>METHODFUND</span>
          )}
          <span className={styles.menuHeader__titleMenu}>{t('mainMenu')}</span>
        </div>
        <button className={styles.menuHeader__close} onClick={onClose}>
          <Icon id="cross" className={styles.icon} />
         
        </button>
      </div>
      {showBackButton && (
          <button className={styles.menuHeader__backButton} onClick={onBack}>
            <Icon id="arrow" className={styles.icon} />
            {t("Back")}
          </button>
        )}
      <div className={styles.menuHeader__content}>
     

        <div className={styles.menuHeader__category}>
          {selectedCategory ? (
            <span className={styles.menuHeader__selectedCategory}>
              [ {" "} {getTranslation(selectedCategory, "category_name")} {" "}]
            </span>
          ) : (
            <span className={styles.menuHeader__selectedCategory}>
              [{" "}{t("All projects")}{" "}]
            </span>
          )}

          {showApplyButton && (
            <button
              className={styles.menuHeader__apply}
              onClick={onApplyFilters}
            >
              {t("Show")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MenuHeader;
