import React, { useMemo } from "react";
import classNames from "classnames";
import { AllContent } from "../../types";
import { useTranslatedContent } from "../../hooks/useTranslatedContent";
import styles from "./AllContentDetailsList.module.less";
import { useTranslation } from "react-i18next";
import { Icon } from "../shared/Icon/Icon";
import { useThemeStore } from "../../stores/themeStore";

interface ContentDetailsProps {
  content: AllContent;
  className?: string;
  itemClassName?: string;
  iconClass?: string;
  showTags?: boolean;
}

const AllContentDetailsList: React.FC<ContentDetailsProps> = React.memo(
  ({ content, className, itemClassName, iconClass, showTags = true }) => {
    const { getTranslation } = useTranslatedContent();
    const { t } = useTranslation();
    const { theme } = useThemeStore();

    const isLightTheme = theme === "light";

    const tags = useMemo(() => {
      if (!showTags || !content?.tags || content.tags.length === 0) return null;

      return content.tags
        .map((tagWrapper, index) => {
          const tag = tagWrapper.tags_id;
          if (!tag) return null;

          const tagText = getTranslation(tag, "tag");

          return (
            <li
              key={`tag-${tag.id || index}`}
              className={classNames(
                styles["course-details__item"],
                styles["course-details__item--tag"],
                {
                  [styles["course-details__item--light"]]: isLightTheme,
                  [styles["course-details__item--dark"]]: !isLightTheme,
                },
                itemClassName
              )}
            >
              {tagText}
            </li>
          );
        })
        .filter(Boolean);
    }, [content?.tags, getTranslation, itemClassName, showTags, isLightTheme]);

    const renderListItem = (
      key: string,
      content: React.ReactNode,
      additionalClassName?: string
    ) => (
      <li
        key={key}
        className={classNames(
          styles["course-details__item"],
          itemClassName,
          additionalClassName,
          {
            [styles["course-details__item--light"]]: isLightTheme,
            [styles["course-details__item--dark"]]: !isLightTheme,
          }
        )}
      >
        {content}
      </li>
    );

    if (!content) {
      return null;
    }

    return (
      <ul className={classNames(styles["course-details"], className)}>
        {content.category &&
          renderListItem(
            `category-${content.category.id || "unknown"}`,
            `${getTranslation(content.category, "category_name")}  `,
            classNames(
              styles["course-details__item--category"],
              styles[
                `${!content.subcategories?.[0] ? "course-details__item--subcategoryWithActual" : ""}`
              ]
            )
          )}

        {content.subcategories?.[0] && (
          <span
            className={classNames(
              styles["course-details__separator"],
              {
                [styles["course-details__separator--light"]]: isLightTheme,
                [styles["course-details__separator--dark"]]: !isLightTheme,
              },
              itemClassName
            )}
          >
            {" "}
            /{" "}
          </span>
        )}

        {content.subcategories?.[0] &&
          renderListItem(
            `subcategory-${content.subcategories[0].subcategories_id || "unknown"}`,
            getTranslation(
              content.subcategories[0].subcategories_id,
              "subcategory_name"
            ),
            classNames(
              styles["course-details__item--category"],
              styles[
                `${!content.archive ? "course-details__item--subcategoryWithActual" : "course-details__item--subcategory"}`
              ]
            )
          )}

        {!content.archive && (
          <>
            <div
              className={classNames(
                styles["course-details__actual"],
                {
                  [styles["course-details__actual--light"]]: isLightTheme,
                  [styles["course-details__actual--dark"]]: !isLightTheme,
                },
                itemClassName
              )}
            >
              <Icon
                id="actual"
                className={classNames(
                  styles["course-details__icon"],
                  {
                    [styles["course-details__icon--light"]]: isLightTheme,
                    [styles["course-details__icon--dark"]]: !isLightTheme,
                  },
                  iconClass
                )}
              />
              <div className={styles["course-details__status"]}>
                {t("actual")}
              </div>
            </div>
          </>
        )}

        {tags}

        {content.translations?.[0]?.date_tag &&
          renderListItem(
            "date-tag",
            getTranslation(content, "date_tag"),
            classNames(
              styles["course-details__item--category"],
              styles["course-details__item--date"]
            )
          )}
      </ul>
    );
  }
);

export default AllContentDetailsList;
