import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { useQuery, useMutation } from "react-query";
import { useFormModalStore } from "../../stores/formStore";
import { useTranslatedContent } from "../../hooks/useTranslatedContent";
import { FormResponse } from "../../types/form";
import { submitFormResponse, fetchForm } from "../../utils/form";
import styles from "./Form.module.less";
import { Icon } from "../shared/Icon/Icon";
import { useTranslation } from "react-i18next";
import CustomSelect from "./CustomSelect/CustomSelect";
import { useThemeStore } from "../../stores/themeStore";

const Form: React.FC = () => {
  const { isOpen, closeModal } = useFormModalStore();
  const { getTranslation } = useTranslatedContent();
  const { t } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState<FormResponse>({
    theme: "",
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const { data: formConfig, isLoading } = useQuery("formConfig", fetchForm);
  const { theme } = useThemeStore();

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        closeModal();
      }
    };

    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.addEventListener("keydown", handleEscape);
    }

    return () => {
      document.body.style.overflow = "unset";
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isOpen, closeModal]);

  const submitMutation = useMutation(
    (data: FormResponse) => submitFormResponse(data),
    {
      onSuccess: () => {
        setIsSubmitted(true);
      },
    }
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submitMutation.mutate(formData);
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  if (!isOpen) return null;

  const modalContent = (
    <div className={styles.backdrop} onClick={handleBackdropClick}>
      <div className={styles.formContainer}>
        <button
          className={styles.closeButton}
          onClick={closeModal}
          aria-label="Close"
        >
          <Icon id="close-dropdown" className={`${styles.icon}`} />
        </button>

        {isLoading ? (
          <div className={styles.loading}>Loading...</div>
        ) : isSubmitted ? (
          <>
            <h2 className={styles.formTitle}>
              {getTranslation(formConfig?.[0], "title")}
            </h2>
            <p className={styles.thankYouMessage}>
              {getTranslation(formConfig?.[0], "thank_you_message")}
            </p>

            <button className={styles.submitButton} onClick={closeModal}>
              {t("Back to site")}
              <Icon
                id="arrow-btn-right"
                className={`${styles.iconSubmit} ${theme !== "light" && styles.iconLight} `}
              />
            </button>
          </>
        ) : (
          <>
            <h2 className={styles.formTitle}>
              {getTranslation(formConfig?.[0], "title")}
            </h2>
            <p className={styles.formDescription}>
              {getTranslation(formConfig?.[0], "description")}
            </p>

            <form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.field}>
                <CustomSelect
                  options={formConfig?.[0]?.translations?.[0]?.theme || []}
                  value={formData.theme}
                  onChange={handleChange}
                  placeholder={t("Select theme")}
                  required
                />
              </div>

              <div className={styles.field}>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  placeholder={getTranslation(formConfig?.[0], "name")}
                />
              </div>

              <div className={styles.field}>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder={getTranslation(formConfig?.[0], "email")}
                />
              </div>

              <div className={styles.field}>
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  placeholder={getTranslation(formConfig?.[0], "number")}
                />
              </div>

              <div className={styles.field}>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder={getTranslation(formConfig?.[0], "message")}
                />
              </div>

              <button
                type="submit"
                className={styles.submitButton}
                disabled={submitMutation.isLoading}
              >
                {t("Submit")}
                <Icon id="arrow-btn-right" className={`${styles.iconSubmit}`} />
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );

  return createPortal(modalContent, document.body);
};

export default Form;
