import { create } from "zustand";

interface DropdownState {
  isOpen: boolean;
  toggleDropdown: () => void;
}

export const useDropdownStore = create<DropdownState>((set) => ({
  isOpen: false,
  toggleDropdown: () => set((state) => ({ isOpen: !state.isOpen })),
  closeDropdown: () => set({ isOpen: false }),
}));
