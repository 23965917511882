import ViewToggleButton from "./ViewToggleButton/ViewToggleButton";
import ThemeToggle from "./ThemeToggle/ThemeToggle";
import LanguageSwitcher from "./LanguageSwitcher/LanguageSwitcher";
import SavedContentDropdown from "./SavedAllContent/SavedContentDropdown";
import { useCurrentRoute } from "../../hooks/useCurrentRoute";
import { useSearchStore } from "../../stores/searchStore";
import classNames from "classnames";
import styles from "./SwitcherBlock.module.less";

import { useMenuStateStore } from "../../stores/menuStore";
import { useThemeStore } from "../../stores/themeStore";

export const SwitcherBlock = () => {
  const { isAllContentList } = useCurrentRoute();
  const { isSearchVisible } = useSearchStore();

  const { isMenuOpen } = useMenuStateStore();
  const { theme } = useThemeStore();

  const isDisabled = isSearchVisible || isMenuOpen;

  return (
    <div
      className={classNames(styles.switcherBlock, {
        [styles.disabled]: isDisabled,
      })}
      aria-disabled={isDisabled}
    >
      <SavedContentDropdown theme={theme} />
      {isAllContentList && <ViewToggleButton theme={theme} />}
      <ThemeToggle />
      <LanguageSwitcher theme={theme} />
    </div>
  );
};
