import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./SquareBlock.module.less";
import { useCategories } from "../../../hooks/useCategories";
import { Icon } from "../../shared/Icon/Icon";
import { useThemeStore } from "../../../stores/themeStore";

const SquareBlock: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { theme } = useThemeStore();
  const { data: categoriesData } = useCategories();

  const isMainPage = location.pathname === "/";
  const isCategoryPage = categoriesData?.categories.some(
    (category) => `/${category.slug}` === location.pathname
  );

  const shouldShow = !isMainPage && !isCategoryPage;

  const handleClick = () => {
    if (shouldShow) {
      navigate(-1);
    }
  };

  return shouldShow ? (
    <div
      className={`${styles.squareBlock} ${styles.clickable}`}
      onClick={handleClick}
    >
      <Icon
        id="arrow"
        className={`${styles.icon} ${theme !== "light" && styles.iconLight}`}
      />
    </div>
  ) : (
    <div
      className={`${styles.squareBlock} ${!isMainPage ? styles.clickable : ""}`}
    ></div>
  );
};

export default SquareBlock;
