import styles from "./ThemeToggle.module.less";
import { Icon } from "../../shared/Icon/Icon";
import { useThemeStore } from "../../../stores/themeStore";

const ThemeToggle = ({
  
  isMenu,
}: {

  isMenu?: boolean;
}) => {
  const { toggleTheme, theme } = useThemeStore();

 

  return (
    <button onClick={toggleTheme} className={styles.themeToggle}>
      <Icon
        id="moon"
        className={`${styles.icon} ${theme !== "light" && styles.iconLight} ${isMenu && styles.iconBig} ${
          theme === "light" && styles.iconActive
        }`}
      />
    </button>
  );
};

export default ThemeToggle;
