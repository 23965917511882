
import React, { useEffect } from "react";
import { useTranslatedContent } from "../../../../hooks/useTranslatedContent";
import styles from "./MenuCategories.module.less";
import { useCategories } from "../../../../hooks/useCategories";
import { Category } from "../../../../types/categories";
import { useMenuStore } from "../../../../stores/menuStore";
import { Icon } from "../../../shared/Icon/Icon";

const MenuCategories: React.FC = () => {
  const { data: categoriesData, isError, error } = useCategories();
  const { getTranslation } = useTranslatedContent();
  const [openAccordions, setOpenAccordions] = React.useState<string[]>([]);
  const {
    currentStep,
    tempSelectedCategory,
    tempSelectedSubcategories,
    setTempCategory,
    setTempSubcategories,
    setCurrentStep,
  } = useMenuStore();

  useEffect(() => {
    if (tempSelectedCategory && window.innerWidth >= 768) {
      setOpenAccordions([tempSelectedCategory]);
    }
  }, [tempSelectedCategory]);

  if (isError) {
    return <div>Error loading categories: {(error as Error).message}</div>;
  }

  if (!categoriesData) {
    return null;
  }

  const getCategorySubcategories = (categoryId: string) => {
    return categoriesData.subcategories.filter((subcategory) =>
      subcategory.categories.some((cat) => cat.categories_id === categoryId)
    );
  };

  const handleCategoryClick = (categoryId: string) => {
    setTempCategory(categoryId);

    if (window.innerWidth < 768) {
      setCurrentStep("subcategories");
    } else {
      if (openAccordions.includes(categoryId)) {
        setOpenAccordions(openAccordions.filter((id) => id !== categoryId));
      } else {
        setOpenAccordions([...openAccordions, categoryId]);
      }
    }
  };

  const handleSubcategoryClick = (subcategoryId: string) => {
    if (tempSelectedSubcategories.includes(subcategoryId)) {
      setTempSubcategories(
        tempSelectedSubcategories.filter((id) => id !== subcategoryId)
      );
    } else {
      setTempSubcategories([...tempSelectedSubcategories, subcategoryId]);
    }
  };

  const renderMobileCategories = () => (
    <ul className={styles.menuCategories__list}>
      {categoriesData.categories.map((category: Category) => (
        <li key={category.id} className={styles.menuCategories__itemWrapper}>
          <button
            className={`${styles.menuCategories__item} ${
              tempSelectedCategory === category.id
                ? styles.menuCategories__item_active
                : ""
            }`}
            onClick={() => handleCategoryClick(category.id)}
          >
            <span>{getTranslation(category, "category_name")}</span>
          </button>
          <Icon id="arrow-right" className={styles.menuCategories__arrow} />
        </li>
      ))}
    </ul>
  );

  const renderMobileSubcategories = () => {
    const selectedCategory = categoriesData.categories.find(
      (cat) => cat.id === tempSelectedCategory
    );

    if (!selectedCategory) return null;

    const subcategories = getCategorySubcategories(selectedCategory.id);

    return (
      <ul className={styles.menuCategories__subcategoriesList}>
        {subcategories.map((subcategory) => (
          <li
            key={subcategory.id}
            className={styles.menuCategories__subitemWrapper}
            onClick={() => handleSubcategoryClick(subcategory.id)}
          >
            <button
              className={`${styles.menuCategories__subitem} ${
                tempSelectedSubcategories.includes(subcategory.id)
                  ? styles.menuCategories__subitem_active
                  : ""
              }`}
            >
              <span>{getTranslation(subcategory, "subcategory_name")}</span>
              {tempSelectedSubcategories.includes(subcategory.id) && (
                <Icon id="close-dropdown" className={styles.iconCross} />
              )}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className={styles.menuCategories}>
      <div className={styles.menuCategories__mobile}>
        {currentStep === "categories"
          ? renderMobileCategories()
          : renderMobileSubcategories()}
      </div>

      <div className={styles.menuCategories__desktop}>
        {categoriesData.categories.map((category: Category) => (
          <li key={category.id} className={styles.menuCategories__section}>
            <div 
              className={styles.menuCategories__categoryDesktopWrapper} 
              onClick={() => handleCategoryClick(category.id)}
            >
              <button
                className={`${styles.menuCategories__categoryDesktop} ${
                  openAccordions.includes(category.id)
                    ? styles.menuCategories__header_active
                    : ""
                }`}
              >
                {getTranslation(category, "category_name")}
              </button>
              <Icon
                id={
                  openAccordions.includes(category.id)
                    ? "arrow-up"
                    : "arrow-right"
                }
                className={styles.menuCategories__arrow}
              />
            </div>
            {openAccordions.includes(category.id) && (
              <div className={styles.menuCategories__subcategories}>
                {getCategorySubcategories(category.id).map((subcategory) => (
                  <li
                    key={subcategory.id}
                    className={styles.menuCategories__subitemWrapper}
                    onClick={() => handleSubcategoryClick(subcategory.id)}
                  >
                    <button
                      className={`${styles.menuCategories__subitem} ${
                        tempSelectedSubcategories.includes(subcategory.id)
                          ? styles.menuCategories__subitem_active
                          : ""
                      }`}
                    >
                      {getTranslation(subcategory, "subcategory_name")}
                      {tempSelectedSubcategories.includes(subcategory.id) && (
                        <Icon
                          id="close-dropdown"
                          className={styles.iconCross}
                        />
                      )}
                    </button>
                  </li>
                ))}
              </div>
            )}
          </li>
        ))}
      </div>
    </div>
  );
};

export default MenuCategories;

