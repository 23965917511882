import React from "react";
import { useLocation } from "react-router-dom";
import Logo from "./Logo/Logo";
import Menu from "./Menu/Menu";
import PathInfo from "./PathInfo/PathInfo";
import Categories from "./Categories/Categories";
import SearchInput from "./SearchInput/SearchInput";
import SquareBlock from "./SquareBlock/SquareBlock";
import SaveContent from "./SaveContent/SaveContent";
import { Icon } from "../shared/Icon/Icon";

import { useCategories } from "../../hooks/useCategories";
import { useThemeStore } from "../../stores/themeStore";
import { useSearchStore } from "../../stores/searchStore";
import { useDropdownStore } from "../../stores/dropdownStore";
import { useMenuStateStore } from "../../stores/menuStore";

import styles from "./Header.module.less";
import YearsList from "../YearsList/YearsList";
import AdditionalFilter from "./AdditionalFilter/AdditionalFilter";
import { useFilters } from "../../hooks/useFilters";
import useIsMobile from "../../hooks/useIsMobile";

const Header: React.FC = () => {
  const isMobile = useIsMobile();

  const location = useLocation();
  const { data: categoriesData } = useCategories();

  const { theme } = useThemeStore();
  const { isOpen } = useDropdownStore();
  const { isMenuOpen } = useMenuStateStore();
  const { isSearchVisible, toggleSearch } = useSearchStore();

  const { selectedCategory } = useFilters();

  const pathSegments = location.pathname.split("/").filter(Boolean);
  const isMainPage = pathSegments.length === 0;
  const categorySlug = pathSegments[0];
  const pageSlug = pathSegments[1];

  const category = categoriesData?.categories.find(
    (cat) => cat.slug === categorySlug
  );

  const isCategoryPage = categoriesData?.categories.some(
    (category) => `/${category.slug}` === location.pathname
  );

  const shouldShow = !isMainPage && !isCategoryPage;
  const isDisabled = isSearchVisible || isOpen || isMenuOpen;

  const renderSearchSection = () => {
    if (!isSearchVisible) return null;

    if (isMobile) {
      return <SearchInput toggleSearch={toggleSearch} />;
    }

    return (
      <div className={styles.desktopSearchWrapper}>
        <SearchInput toggleSearch={toggleSearch} />
      </div>
    );
  };
  const isDeepPath = () => {
    const segments = location.pathname.split("/").filter(Boolean);
    return segments.length > 1;
  };

  return (
    <header className={styles.header}>
      {isDeepPath() ? (
        <SquareBlock />
      ) : (
        !isMobile && !isDisabled && !selectedCategory && <SquareBlock />
      )}
      {!isSearchVisible ? (
        <div className={styles.header__mainSection}>
          {!isSearchVisible ? <Logo /> : renderSearchSection()}

          <div className={styles.dropdownSection}>
            {shouldShow ? (
              <PathInfo category={category} pageSlug={pageSlug} />
            ) : (
              <div></div>
            )}
          </div>

          <div className={styles.header__iconsBlock}>
            <div className={styles.header__iconsWrapper}>
              <div onClick={toggleSearch}>
                <Icon
                  id="zoom"
                  className={`${styles.icon} ${theme !== "light" && styles.iconLight}`}
                />
              </div>
              <Menu />
            </div>
          </div>
        </div>
      ) : (
        <> {renderSearchSection()}</>
      )}

      <SaveContent />

      {!pageSlug && !isMobile && (
        <div className={styles.container__content}>
          <div className={styles.header__filterSection}>
            {!shouldShow && !isSearchVisible && <Categories />}
          </div>
        </div>
      )}

      {!pageSlug && isMobile && (
        <div className={styles.container__content}>
          <YearsList />
          <AdditionalFilter />
        </div>
      )}
    </header>
  );
};

export default Header;
