import React, { useRef, useEffect } from "react";

import { useArtistModalStore } from "../../stores/artistModalStore";
import { useFormModalStore } from "../../stores/formStore";

import styles from "./AboutFundPage.module.less";
import { useAboutFund } from "../../hooks/useAboutFund";
import { useTranslatedContent } from "../../hooks/useTranslatedContent";
import { setupLinkHandlers } from "../../helpers/linkHandler";
import { Footer } from "../../components/Footer/Footer";
import { useTranslation } from "react-i18next";
import { SocialNetwork } from "../../types/about_fund";

const API_URL = import.meta.env.VITE_API_URL || "http://0.0.0.0:8055";

export const AboutFundPage: React.FC = () => {
  const { data, isLoading, error } = useAboutFund();
  const { getTranslation, i18n } = useTranslatedContent();
  const pageRef = useRef<HTMLDivElement>(null);
  const openArtistModal = useArtistModalStore((state) => state.openModal);
  const openFormModal = useFormModalStore((state) => state.openModal);
  const { t } = useTranslation();

  console.log("AboutFundPage", data);
  useEffect(() => {
    if (!pageRef.current) return;

    const cleanup = setupLinkHandlers(pageRef.current, {
      openArtistModal,
      openFormModal,
    });

    return cleanup;
  }, [openArtistModal, openFormModal, data]);

  if (isLoading) {
    return <div className={styles.aboutFund__loading}>Loading...</div>;
  }

  if (error) {
    return <div className={styles.aboutFund__error}>Error loading data</div>;
  }

  if (!data?.[0]) {
    return <div className={styles.aboutFund__empty}>No data available</div>;
  }

  const aboutFundData = data[0];
  const description = getTranslation(aboutFundData, "description") || "";

  const actualProjects =
    aboutFundData.projects?.filter(
      (project) => !project?.allContent_id?.archive
    ) || [];

  const archiveProjects =
    aboutFundData.projects?.filter(
      (project) => project?.allContent_id?.archive === true
    ) || [];

  const reports = aboutFundData.reports || [];

  const renderProjectsList = (projects: typeof actualProjects) => {
    return (
      <div className={styles.aboutFund__projectsList}>
        <table className={styles.aboutFund__table}>
          <thead>
            <tr>
              <th>{t("Project")}</th>
              <th>{t("Name")}</th>
              <th>{t("Who")}</th>
              <th>{t("Year")}</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => {
              const title =
                getTranslation(project.allContent_id, "title") || "";
              const category =
                getTranslation(
                  project.allContent_id.category,
                  "category_name"
                ) || "";
              const members =
                project.allContent_id.members
                  ?.map((member) => {
                    const translations = member.artist_info_id.translations;
                    const artistName =
                      translations?.length === 2
                        ? translations[i18n.language === "en" ? 1 : 0]
                            .artist_name
                        : translations?.[0]?.artist_name || "";
                    return artistName;
                  })
                  .filter(Boolean)
                  .join(", ") || "";
              const year = project.allContent_id.years?.[0]?.years_id?.year;

              return (
                <tr
                  key={project.allContent_id.slug}
                  onClick={() => {
                    window.location.href = `/projects/${project.allContent_id.slug}`;
                  }}
                >
                  <td>{category}</td>
                  <td>{title}</td>
                  <td>{members}</td>
                  <td>{year}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div ref={pageRef} className={styles.aboutFund}>
      <div
        className={styles.aboutFund__description}
        dangerouslySetInnerHTML={{ __html: description }}
      />

      <div className={styles.aboutFund__section}>
        <h2 className={styles.aboutFund__sectionTitle}>{t("Actual")}</h2>
        {renderProjectsList(actualProjects)}
      </div>

      {archiveProjects.length > 0 && (
        <div className={styles.aboutFund__section}>
          <h2 className={styles.aboutFund__sectionTitle}>{t("archive")}</h2>
          {renderProjectsList(archiveProjects)}
        </div>
      )}

      {reports.length > 0 && (
        <div className={styles.aboutFund__sectionReports}>
          <h2 className={styles.aboutFund__reportsTitle}>{t("Reports")}</h2>
          <div className={styles.aboutFund__reportsList}>
            {reports.map((report) => {
              const title = getTranslation(report, "title") || "";
              return (
                <div className={styles.aboutFund__reportLink} key={report.id}>
                  {title}
                  <a
                    href={`${API_URL}/assets/${report.file}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("View")}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className={styles.aboutFund__info}>
        <div className={styles.aboutFund__info__contacts}>
          <h3 className={styles.aboutFund__info__title}>
            {getTranslation(aboutFundData, "contacts_title")}
          </h3>

          <ul className={styles.aboutFund__info__list}>
            {aboutFundData?.social_network?.map((social, index) => (
              <li key={index}>
                <a
                  href={social.value}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.aboutFund__info__contactsItem}
                >
                  {social.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.aboutFund__info__support}>
          <h3 className={styles.aboutFund__info__title}>
            {getTranslation(aboutFundData, "support_title")}
          </h3>
          <ul className={styles.aboutFund__info__list}>
            {getTranslation(aboutFundData, "support")?.map(
              (item: SocialNetwork, index: number) => (
                <li
                  className={styles.aboutFund__info__supportItems}
                  key={index}
                >
                  <div className={styles.aboutFund__info__supportItem}>
                    <div className={styles.aboutFund__info__supportTitle}>
                      {item.title}
                    </div>
                    <div className={styles.aboutFund__info__supportValue}>
                      {item.value}
                    </div>
                  </div>
                </li>
              )
            )}
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
};
