import { create } from "zustand";
import { persist } from "zustand/middleware";
import axios from "axios";
import { AllContent } from "../types";
import { useEffect } from "react";

const API_URL = import.meta.env.VITE_API_URL || "http://0.0.0.0:8055";

interface SavedContentState {
  savedContent: AllContent[];
  savedContentIds: (number | string)[];
  error: string | null;
  isLoading: boolean;
  initialized: boolean;
  addSavedContent: (content: AllContent) => void;
  removeSavedContent: (id: number | string) => void;
  fetchSavedContent: () => Promise<void>;
  setInitialized: (value: boolean) => void;
}

export const useSavedContentStore = create<SavedContentState>()(
  persist(
    (set, get) => ({
      savedContent: [],
      savedContentIds: [],
      error: null,
      isLoading: false,
      initialized: false,

      setInitialized: (value: boolean) => set({ initialized: value }),

      addSavedContent: (content: AllContent) => {
        const state = get();

        if (state.savedContentIds.includes(content.id)) return;

        set((state) => ({
          savedContentIds: [...state.savedContentIds, content.id],
          savedContent: [...state.savedContent, content],
        }));
      },

      removeSavedContent: (id: number | string) => {
        set((state) => ({
          savedContentIds: state.savedContentIds.filter(
            (contentId) => contentId !== id
          ),
          savedContent: state.savedContent.filter(
            (content) => content.id !== id
          ),
        }));
      },

      fetchSavedContent: async () => {
        const { savedContentIds, isLoading } = get();

        if (isLoading) return;

        if (savedContentIds.length === 0) {
          set({ savedContent: [], error: null, initialized: true });
          return;
        }

        set({ isLoading: true });

        try {
          const response = await axios.get(`${API_URL}/items/allContent`, {
            params: {
              filter: { id: { _in: savedContentIds } },
              fields: [
                "*",
                "category.*",
                "category.translations.*",
                "translations.*",
                "subcategories.*",
                "subcategories.translations.*",
                "subcategories.subcategories_id.translations.*",
                "subcategories.subcategories_id",
                "subcategories.translations.*",
              ],
            },
          });

          const currentState = get();
          const currentIds = new Set(currentState.savedContentIds);
          const fetchedContent = response.data.data.filter(
            (content: AllContent) => currentIds.has(content.id)
          );

          set({
            savedContent: fetchedContent,
            error: null,
            isLoading: false,
            initialized: true,
          });
        } catch (error) {
          console.error("Error fetching saved content:", error);
          set({
            error: "Failed to fetch saved content",
            isLoading: false,
            initialized: true,
          });
        }
      },
    }),
    {
      name: "saved-content-storage",
      partialize: (state) => ({
        savedContentIds: state.savedContentIds,
      }),

      onRehydrateStorage: () => (state) => {
        if (state) {
          state.setInitialized(false);
          state.fetchSavedContent();
        }
      },
    }
  )
);

export const useSavedContent = () => {
  const store = useSavedContentStore();
  const { fetchSavedContent, initialized, savedContentIds } = store;

  useEffect(() => {
    let mounted = true;

    const initializeContent = async () => {
      if (!initialized) {
        await fetchSavedContent();
      }
    };

    if (mounted) {
      initializeContent();
    }

    return () => {
      mounted = false;
    };
  }, [initialized, fetchSavedContent]);

  useEffect(() => {
    if (initialized) {
      fetchSavedContent();
    }
  }, [savedContentIds, initialized, fetchSavedContent]);

  return store;
};
