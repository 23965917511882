import React, { useCallback, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCategories } from "../../../hooks/useCategories";
import { useFilters } from "../../../hooks/useFilters";
import { useTranslatedContent } from "../../../hooks/useTranslatedContent";
import { CategoriesData } from "../../../types/categories";
import classNames from "classnames";
import styles from "./SelectedFilters.module.less";
import { Icon } from "../Icon/Icon";
import { useTranslation } from "react-i18next";
import { useDropdownStore } from "../../../stores/selectedFilterStore";

const SelectedFilters: React.FC = () => {
  const { data: categoriesData, isError, error } = useCategories();
  const {
    selectedCategory,
    selectedSubcategories,
    setSelectedCategory,
    setSelectedSubcategories,
  } = useFilters();
  const { getTranslation } = useTranslatedContent();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { isDropdownOpen, openDropdown, closeDropdown } = useDropdownStore();

  const typedCategoriesData = categoriesData as CategoriesData | undefined;

  const handleCategoryClick = useCallback(
    (categoryId: string, categorySlug: string) => {
      const newCategory = selectedCategory === categoryId ? null : categoryId;
      setSelectedCategory(newCategory);
      navigate(newCategory ? `/${categorySlug}` : "/");
      setSelectedSubcategories([]);
    },
    [selectedCategory, setSelectedCategory, setSelectedSubcategories, navigate]
  );

  const handleSubcategoryClick = useCallback(
    (subcategoryId: string) => {
      setSelectedSubcategories((prev) =>
        prev.includes(subcategoryId)
          ? prev.filter((id) => id !== subcategoryId)
          : [...prev, subcategoryId]
      );
    },
    [setSelectedSubcategories]
  );

  const handleResetAll = useCallback(() => {
    setSelectedCategory(null);
    setSelectedSubcategories([]);
    navigate("/");
    closeDropdown();
  }, [setSelectedCategory, setSelectedSubcategories, navigate, closeDropdown]);

  const selectedCategoryData = useMemo(() => {
    if (!selectedCategory || !typedCategoriesData) return null;
    return typedCategoriesData.categories.find(
      (category) => category.id === selectedCategory
    );
  }, [selectedCategory, typedCategoriesData]);

  const filteredSubcategories = useMemo(() => {
    if (!selectedCategory || !typedCategoriesData) return [];
    return typedCategoriesData.subcategories.filter((subcategory) =>
      subcategory.categories.some(
        (cat) => cat.categories_id === selectedCategory
      )
    );
  }, [selectedCategory, typedCategoriesData]);

  const getSelectionSummary = useCallback(() => {
    const parts = [];

    if (selectedCategoryData) {
      parts.push(`[${getTranslation(selectedCategoryData, "category_name")}]`);
    }

    if (selectedSubcategories.length > 0) {
      const subcategoryNames = selectedSubcategories
        .map((id) => {
          const sub = filteredSubcategories.find((s) => s.id === id);
          return sub ? getTranslation(sub, "subcategory_name") : "";
        })
        .filter(Boolean);

      if (subcategoryNames.length > 0) {
        parts.push(`[${subcategoryNames.join(" + ")}]`);
      }
    }

    return parts.filter(Boolean).join(" / ");
  }, [
    selectedCategoryData,
    selectedSubcategories,
    filteredSubcategories,
    getTranslation,
  ]);

  if (isError) {
    return <div>Error: {error?.message}</div>;
  }

  if (!typedCategoriesData) {
    return null;
  }

  const renderMobileView = () => (
    <div className={styles.mobile}>
      {(selectedCategory ||
        selectedSubcategories.length > 0 ||
        isDropdownOpen) &&
        getSelectionSummary().trim().length > 0 && (
          <div className={styles.selectedFiltersBar} onClick={openDropdown}>
            {getSelectionSummary()}
          </div>
        )}

      {isDropdownOpen && (
        <div className={styles.dropdownPanel}>
          <div>
            {selectedCategoryData && (
              <>
                <div className={styles.categoryHeader}>
                  {getTranslation(selectedCategoryData, "category_name")}
                  <button
                    className={styles.removeButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (selectedCategoryData) {
                        handleCategoryClick(
                          selectedCategoryData.id,
                          selectedCategoryData.slug
                        );
                      }
                    }}
                  >
                    <Icon
                      id="close-dropdown"
                      className={styles["icon-cross"]}
                    />
                  </button>
                </div>

                <div className={styles.subcategoryList}>
                  {filteredSubcategories.map((subcategory) => (
                    <li
                      key={subcategory.id}
                      className={styles.subcategoryWrapper}
                    >
                      <button
                        className={classNames(styles.subcategoryItem, {
                          [styles.subcategoryItemSelected]:
                            selectedSubcategories.includes(subcategory.id),
                        })}
                        onClick={() => handleSubcategoryClick(subcategory.id)}
                      >
                        {getTranslation(subcategory, "subcategory_name")}
                        {selectedSubcategories.includes(subcategory.id) && (
                          <Icon
                            id="close-dropdown"
                            className={styles["icon-crossActive"]}
                          />
                        )}
                      </button>
                    </li>
                  ))}
                </div>
              </>
            )}

            {!selectedCategoryData && (
              <div className={styles.categoryList}>
                <div className={styles.categoryHeader}>
                  {t("All projects")}
                  <button
                    className={styles.removeButton}
                    onClick={closeDropdown}
                  >
                    <Icon
                      id="close-dropdown"
                      className={styles["icon-cross"]}
                    />
                  </button>
                </div>
                {typedCategoriesData.categories.map((category) => (
                  <div
                    key={category.id}
                    className={styles.categoryItem}
                    onClick={() =>
                      handleCategoryClick(category.id, category.slug)
                    }
                  >
                    {getTranslation(category, "category_name")}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={styles.actionButtons}>
            <button className={styles.showButton} onClick={closeDropdown}>
              {t("Show")}
            </button>
            <button className={styles.resetButton} onClick={handleResetAll}>
              {t("Clear filters")}
            </button>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <>
    { location.pathname === "/" && <div className={styles.root}>{renderMobileView()}</div>}
    </>)
};

export default React.memo(SelectedFilters);




