import { create } from 'zustand';
import { useLocalStorage } from "usehooks-ts";
import React from 'react';

interface FormModalState {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  openModal: () => void;
  closeModal: () => void;
}

// Отримуємо початковий стан з localStorage
const getInitialState = () => {
  try {
    const savedIsOpen = window.localStorage.getItem("formModalIsOpen");
    return {
      isOpen: savedIsOpen === 'true'
    };
  } catch {
    return {
      isOpen: false
    };
  }
};

// Основний store
export const useFormModalStore = create<FormModalState>()((set) => ({
  ...getInitialState(),
  
  setIsOpen: (isOpen) => set(() => {
    window.localStorage.setItem("formModalIsOpen", String(isOpen));
    return { isOpen };
  }),
  
  openModal: () => set(() => {
    window.localStorage.setItem("formModalIsOpen", "true");
    return { isOpen: true };
  }),
  
  closeModal: () => set(() => {
    window.localStorage.setItem("formModalIsOpen", "false");
    return { isOpen: false };
  })
}));

// Хук з синхронізацією localStorage
export const useFormModal = () => {
  const store = useFormModalStore();
  
  const [storedIsOpen] = useLocalStorage<boolean>(
    "formModalIsOpen",
    store.isOpen
  );

  // Синхронізуємо значення при монтуванні
  React.useEffect(() => {
    if (storedIsOpen !== store.isOpen) {
      if (storedIsOpen) {
        store.openModal();
      } else {
        store.closeModal();
      }
    }
  }, []);

  return store;
};