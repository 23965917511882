
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuCategories from './MenuCategories/MenuCategories';
import styles from './Menu.module.less';
import { Icon } from '../../shared/Icon/Icon';
import { useFilters } from '../../../hooks/useFilters';
import { useMenuStore, useMenuStateStore } from '../../../stores/menuStore';
import { useThemeStore } from '../../../stores/themeStore';
import { useCategories } from '../../../hooks/useCategories';
import MenuHeader from './HeaderMenu/HeaderMenu';
import useIsMobile from '../../../hooks/useIsMobile';
import SavedContentDropdown from '../../SwitcherBlock/SavedAllContent/SavedContentDropdown';
import ThemeToggle from '../../SwitcherBlock/ThemeToggle/ThemeToggle';
import LanguageSwitcher from '../../SwitcherBlock/LanguageSwitcher/LanguageSwitcher';
import { useSearchStore } from '../../../stores/searchStore';


const Menu = () => {
  const navigate = useNavigate();
  const { isMenuOpen, toggleMenu, closeMenu } = useMenuStateStore();
   const { closeSearch } = useSearchStore();
  const {
    tempSelectedCategory,
    tempSelectedSubcategories,
    resetTempSelections,
    currentStep,
    setCurrentStep,
    setTempCategory,
    setTempSubcategories
  } = useMenuStore();

  const { 
    selectedCategory, 
    selectedSubcategories, 
    setSelectedCategory, 
    setSelectedSubcategories 
  } = useFilters();
  
  const { theme } = useThemeStore();
  const { data: categoriesData } = useCategories();
  const isMobile = useIsMobile();


  useEffect(() => {
    if (isMenuOpen) {
      if (selectedCategory) {
        setCurrentStep('subcategories');
        setTempCategory(selectedCategory);
        setTempSubcategories(selectedSubcategories);
      } else {
        setCurrentStep('categories');
        setTempCategory(null);
        setTempSubcategories([]);
      }
    }
  }, [isMenuOpen, selectedCategory, selectedSubcategories, setTempCategory, setTempSubcategories, setCurrentStep]);

  const handleApplyFilters = () => {
    setSelectedCategory(tempSelectedCategory);
    setSelectedSubcategories(tempSelectedSubcategories);
    navigate('/');
    closeMenu();
  };

  const handleIconClick = () => {
   toggleMenu();
   closeSearch();
  };

  const handleClose = () => {
    resetTempSelections();
    closeMenu();
  };

  const handleBack = () => {
    setCurrentStep("categories");
    setTempCategory(null);
    setTempSubcategories([]);
  };

  const selectedCategoryData = categoriesData?.categories.find(
    cat => cat.id === tempSelectedCategory
  );

  return (
    <>
      <button className={styles.menu__button} onClick={handleIconClick}>
        <Icon
          id="menu"
          className={`${styles.icon} ${theme !== "light" && styles.iconLight}`}
        />
      </button>

      {isMenuOpen  && (
        <div className={styles.menu__dropdown}>
          <div>
            <MenuHeader
              onClose={handleClose}
              onApplyFilters={handleApplyFilters}
              selectedCategory={selectedCategoryData}
              onBack={handleBack}
              showBackButton={currentStep === "subcategories"}
              showApplyButton={true}
            />
            <MenuCategories />
          </div>
          
          {isMobile && (
            <div className={styles.menu__dropdown__switchers}>
              <SavedContentDropdown theme="dark" isMenu={true} />
              <ThemeToggle  isMenu={true} />
              <LanguageSwitcher theme="dark" isMenu={true}  />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Menu;