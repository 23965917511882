import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import styles from "./LanguageSwitcher.module.less";
import { Icon } from "../../shared/Icon/Icon";

const LanguageSwitcher = ({ theme }: { theme: string; isMenu?: boolean }) => {
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language);

  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);

  const toggleLanguage = () => {
    const newLang = currentLang === "uk" ? "en" : "uk";
    i18n.changeLanguage(newLang);
  };

  return (
    <div onClick={toggleLanguage} className={styles.btn}>
      <Icon
        id="language"
        className={`${styles.icon} ${theme !== "light" && styles.iconLight} ${
          currentLang !== "uk" && styles.iconActive
        }`}
      />
    </div>
  );
};

export default LanguageSwitcher;
